import { createElement, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { growl } from '@wmgtech/legato'

import { IAdvertiser } from 'modules/Admin'
import { ADVERTISERS_LIST, CREATE_ADVERTISER, DELETE_ADVERTISER, UPDATE_ADVERTISER } from '../constants'
import { Toast } from 'components/Toast'

interface IAdvertiserInput {
  name: string
}

interface IAdvertiserUpdateInput {
  id: string
  name: string
}

export const useAdvertisers = () => {
  const { data: advertisers, loading } = useQuery<{ getAdvertisers: [IAdvertiser] }>(ADVERTISERS_LIST)

  const [createAdvertiserMutation] = useMutation<{ createAdvertiser: IAdvertiser }, { input: IAdvertiserInput }>(CREATE_ADVERTISER,{
    update(cache) {
      cache.modify({
        fields: {
          getAdvertisers() {},
        },
      })
    },
  })

  const [updateAdvertiserMutation] = useMutation<{ updateAdvertiser: IAdvertiser }, { input: IAdvertiserUpdateInput }>(UPDATE_ADVERTISER,{
    update(cache) {
      cache.modify({
        fields: {
          getAdvertisers() {},
        },
      })
    },
  })

  const [deleteAdvertiserMutation] = useMutation<{ deleteAdvertiser: { data: any } }>(DELETE_ADVERTISER, {
    update(cache) {
      cache.modify({
        fields: {
          getAdvertisers() {},
        },
      })
    },
  })

  const createAdvertiser = useCallback(
    async ( name : string) => {
      await createAdvertiserMutation({
        variables: {
          input: { name },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been created successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [createAdvertiserMutation],
  )

  const updateAdvertiser = useCallback(
    async (id: string, name: string) => {
      await updateAdvertiserMutation({
        variables: {
          input: { id, name },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been saved successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [updateAdvertiserMutation],
  )

  const deleteAdvertiser = useCallback<(id: string) => void>(
    async (id) => {
      await deleteAdvertiserMutation({
        variables: {
          id,
        },
      })

      if (advertisers?.getAdvertisers) {
        const category = advertisers?.getAdvertisers.find((adv) => adv.id === id)
        if (category) {
          growl.success({
            message: createElement(Toast, { message: `Category ${category.name} has been successfully deleted` }),
            containerStyle: 'tint',
            colorType: 'success',
            icon: 'check',
          }, { autoClose: 3000 })
        }
      }

    },
    [deleteAdvertiserMutation, advertisers],
  )

  const categoryHash = useMemo<{[id: string]: string }>(() => advertisers?.getAdvertisers?.reduce((acc, item) => ({
    ...acc,
    [item.id]: item.name,
  }), {}) ?? {}, [advertisers?.getAdvertisers])

  const getAdvertiserById = useCallback((id: string) => categoryHash[id], [categoryHash])

  return {
    advertisers: advertisers?.getAdvertisers,
    loading,
    createAdvertiser,
    updateAdvertiser,
    deleteAdvertiser,
    getAdvertiserById,
  }
}
