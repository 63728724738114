import React, { FC, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { Select, InputGroup } from '@wmgtech/legato'
import { useAdvertisers } from 'modules/Advertisers/services'
import { IFieldError, validationAdapter } from 'components/Fields'

export const FormAdvertiserSelect: FC = () => {
  const NAME = 'advertiser'
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController({ name: NAME })
  const { advertisers } = useAdvertisers()

  const handleChange = useCallback(({ id }: { id: string; }) => { onChange(id) }, [onChange])

  const validation = useCallback((error?: IFieldError) => ({
    required: true,
    ...validationAdapter(error),
  }),[])

  return (
    <InputGroup label="Advertiser" validation={validation(errors[NAME] as IFieldError)}>
      <Select
        list={advertisers ?? []}
        onSelect={handleChange}
        placeholder="Select Advertiser"
        getKey={(el) => el.id}
        getTitle={(el) => el.name}
        {...rest}
      />
    </InputGroup>
  )
}
