import { createElement, useCallback, useMemo } from 'react'
import compose from "lodash/fp/compose";
import { useMutation, useQuery } from '@apollo/client'
import { useSearchParams } from "react-router-dom";
import {
  CREATE_LABEL,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  GET_LABELS,
  SORT_DIRECTION,
  SORT_FIELD_PARAM,
  UPDATE_LABEL,
} from '../constants'
import { GET_LABELS_LIST, ILabel } from 'modules/Admin'
import { ILabelListInput } from "../types";
import { pageParams, searchTerm, sortParams } from "../helpers";
import { TPaginatedResponse } from "../../../shared/types";
import { growl, TTableSortConfig, TTableSortDirection } from "@wmgtech/legato";
import { Toast } from "../../../components/Toast";

type TInput<T> = {
  input: T
}
type TCrateLabelInput = TInput<{ name: string, advertiser?: string }>
type TUpadeLabelInput = TInput<{ id: string, name: string }>


export const useLabels = (limit?: number) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getLabelListVariables = useMemo<ILabelListInput>(
    () => compose(
      pageParams(searchParams),
      sortParams(searchParams),
      searchTerm(searchParams),
    )({
      input: {
        limit: limit || 10,
        offset: 0,
        sortOrder: {
          direction: DEFAULT_SORT_DIRECTION,
          field: DEFAULT_SORT_FIELD,
        },
      },
    }),
    [limit, searchParams],
  )

  const { data: labels, loading } = useQuery<{ labelsList: TPaginatedResponse<ILabel> }, ILabelListInput>(GET_LABELS_LIST, {
    variables: getLabelListVariables,
    skip: typeof limit === 'undefined',
  })

  const handleSort = useCallback<(field: string) => void>(
    (key: string) => {
      const currentSortField = searchParams.get(SORT_FIELD_PARAM)
      const currentSortDirection = searchParams.get(SORT_DIRECTION)
      if (currentSortField === key && currentSortDirection === 'ASC') {
        searchParams.set(SORT_DIRECTION, 'DESC')
      } else if (currentSortField === key && currentSortDirection === 'DESC') {
        searchParams.set(SORT_DIRECTION, 'ASC')
      } else {
        searchParams.set(SORT_FIELD_PARAM, key)
        searchParams.set(SORT_DIRECTION, DEFAULT_SORT_DIRECTION)
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const sortState = useMemo<TTableSortConfig>(
    () => ({
      key: searchParams.get(SORT_FIELD_PARAM) || DEFAULT_SORT_FIELD,
      direction: (searchParams.get(SORT_DIRECTION) || DEFAULT_SORT_DIRECTION) as TTableSortDirection,
    }),
    [searchParams],
  )

  const { data } = useQuery<{ getLabels: ReadonlyArray<ILabel> }>(GET_LABELS)

  const [createLabelMutation] = useMutation<{ createLabel: ILabel }, TCrateLabelInput>(CREATE_LABEL, {
    update(cache) {
      cache.modify({
        fields: {
          labelsList() {},
        },
      })
    },
  })

  const [updateLabelMutation] = useMutation<{ updateLabel: ILabel }, TUpadeLabelInput>(UPDATE_LABEL)

  const createLabel = useCallback(
    async (name: string, advertiser?: string) => {
      await createLabelMutation({
        variables: {
          input: { name, ...(advertiser && { advertiser }) },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been created successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [createLabelMutation],
  )

  const updateLabel = useCallback(
    async (id: string, name: string, advertiser?: string) => {
      await updateLabelMutation({
        variables: {
          input: { id, name, ...(advertiser !== undefined && { advertiser }) },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${name} has been saved successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [updateLabelMutation],
  )

  return {
    labelsList: useMemo<TPaginatedResponse<ILabel>>(
      () => ({
        data: labels?.labelsList?.data ?? [],
        total: labels?.labelsList?.total ?? 0,
      }),
      [labels],
    ),
    loading,
    onSort: handleSort,
    sortState,
    labels: useMemo(() => data?.getLabels ?? [], [data]),
    createLabel,
    updateLabel,
  }
}
