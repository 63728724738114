import { FC, useCallback, useMemo } from 'react'
import { Table } from '@wmgtech/legato'

import { ActionButton } from 'components/ActionButton'
import { ColumnsType, IAdvertiser, ModalForm, useAdmin } from 'modules/Admin'
import { ConditionRender, SkeletonTable } from 'components'
import { useAdvertisers } from 'modules/Advertisers/services'
import { TConfirmationData, useConfirmation } from 'components/Confirmation'
import styles from './advertisers.module.scss'

interface IAdvertiserInput {
  name: string
  id?: string
}

export const Advertisers: FC = () => {
  const { advertisers, loading, createAdvertiser, updateAdvertiser, deleteAdvertiser } = useAdvertisers()

  const { handleEdit } = useAdmin()

  const onSubmit = useCallback(
    async ({ id, name }: IAdvertiserInput) => {
      if (typeof id === 'undefined') {
        return createAdvertiser(name)
      }
      return updateAdvertiser(id, name )
    },
    [createAdvertiser, updateAdvertiser],
  )

  const { onConfirm, element } = useConfirmation(
    deleteAdvertiser,
    (message) => (<>Are you sure you want to delete advertiser <b>{message}</b> ?</>),
    'Delete',
  )

  const getAlertMessage = useCallback((record: any) => {
    const labels = record?.labels?.map(({ name }: { name: string }) => <p>{name}</p>)
    const campaigns = record?.campaigns?.map(({ name }: { name: string }) => <p>{name}</p>)
    return (
      <>
        <p style={{ marginBottom: '10px' }}>
          {!!record?.labels?.length && (
            <>
              The advertiser <b>{record.name}</b> is assigned to labels: {labels}
            </>
          )}
        </p>
        <p style={{ marginBottom: '10px' }}>
          {!!record?.campaigns?.length && (
            <>
              The advertiser <b>{record.name}</b> is assigned to campaigns: {campaigns}
            </>
          )}
        </p>
      </>
    )
  }, [])

  const columns = useMemo<ColumnsType<IAdvertiser>[]>(
    () => [
      {
        dataIndex: 'name',
        title: 'Name',
        key: 'name',
        sortable: false,
        align: 'left',
      },
      {
        dataIndex: 'action',
        key: 'action',
        sortable: false,
        width: '63px',
        align: 'center',
        render: (el: any, record: any) => (
          <ActionButton
            handleEdit={() => handleEdit((record as any))}
            handleDelete={() => onConfirm({
              data: record.id,
              message: `${record.name}`,
              ...((record?.campaigns.length || record?.labels.length) && { subMessage: getAlertMessage(record) }),
            })}
          />
        ),
      },
    ],
    [handleEdit, onConfirm, getAlertMessage],
  )

  return (
    <div className={styles.container}>
    <ConditionRender condition={!loading} component={
      <Table
        data={advertisers || []}
        columns={columns}
        rowKey={({ id }) => id}
        pagination={false}
      />
    } secondComponent={<SkeletonTable lines={10}/>}
    />
      {element}
      <ModalForm onSubmit={onSubmit}/>
    </div>
  )
}
