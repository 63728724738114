import { FC } from 'react'
import { FormSearchArtist } from '../SearchArtist'
import { FormSearchProduct } from '../SearchProduct'
import { FormCategorySelect } from '../SearchCategory'
import { FormAdvertiserSelect } from '../SearchAdvertiser'
import { InfoField } from '../InfoField'

export const OtherGenerateCampaignForm: FC = () => (
  <>
    <FormAdvertiserSelect />
    <FormCategorySelect />
    <FormSearchArtist />
    <FormSearchProduct />
    <InfoField />
  </>
)
