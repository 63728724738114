import { useCallback, useRef, useState } from 'react'
import { ConfirmationModal } from './ConfirmationModal'
import { TConfirmationData } from './types'

function defaultMessageCreator(message: string | null) {
  return typeof message === 'string' ? message : 'Are you sure?'
}

export const useConfirmation = <T, >(
  cb: (data: T) => void,
  createMessage: (message: string | null) => JSX.Element | string = defaultMessageCreator,
  actionText?: string,
) => {

  const args = useRef<T>()

  const [content, setContent] = useState<string | null>(null)
  const [additionalContent, setAdditionalContent] = useState<string>('')

  const handleClose = useCallback(
    () => setContent(null),
    [],
  )

  const handleConfirm = useCallback<(data: TConfirmationData<T>) => void>(
    ({ data, message, subMessage = '' }) => {
      args.current = data;
      setContent(message)
      setAdditionalContent(subMessage)
    },
    [],
  )

  const handleAction = useCallback(
    () => {
      if (args.current) {
        cb(args.current)
      }
      handleClose()
    },
    [cb, handleClose],
  )

  return {
    onConfirm: handleConfirm,
    element: (
      <ConfirmationModal
        actionText={actionText}
        isOpen={typeof content === 'string'}
        setClose={handleClose}
        confirmationText={createMessage(content)}
        onAction={handleAction}
        additionalConfirmationText={additionalContent}
      />
    ),
  }
}
