import { useMemo } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_LABELS, GET_ROLES, GET_TEAMS } from '../constants'
import { IAdvertiser, ILabel, IRole, ITeam } from '../types'
import { activeLabelVar } from "modulesCommon/store";
import { ADVERTISERS_LIST } from "../../Advertisers/constants";

type TDataset = 'role' | 'teams' | 'labels' | 'advertisers'

const config: Record<TDataset, any> = {
  labels: {
    query: GET_LABELS,
    getter: (data: { getLabels: ReadonlyArray<ILabel> } | undefined) => data?.getLabels ?? [],
  },
  role: {
    query: GET_ROLES,
    getter: (data: { getRoles: ReadonlyArray<IRole> } | undefined) => data?.getRoles ?? [],
  },
  teams: {
    query: GET_TEAMS,
    getter: (data: { getTeamsList: ReadonlyArray<ITeam> } | undefined) => data?.getTeamsList ?? [],
  },
  advertisers: {
    query: ADVERTISERS_LIST,
    getter: (data: { getAdvertisers: ReadonlyArray<IAdvertiser> } | undefined) => data?.getAdvertisers ?
      [{ id: null, name: 'Not Selected' }, ...data?.getAdvertisers] : [],
  },
}

export function getOptions<T extends { id: string, name: string }>(list: ReadonlyArray<T>) {
  return list.map(({ id, name }) => ({ key: id, title: name }))
}

export const useDataset = (dataset: TDataset, disabled?: boolean) => {
  const currentLabel = useReactiveVar(activeLabelVar)

  const variables = {
    label_id: undefined,
  }

  if (dataset === 'teams') {
    variables.label_id = currentLabel
  }

  const { data, loading } = useQuery(config[dataset].query, {
    skip: disabled,
    variables,
  })

  return {
    options: useMemo(() => getOptions(config[dataset].getter(data)), [data, dataset]),
    loading,
  }
}
