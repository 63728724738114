import { FC } from 'react'
import { IconButton } from '@wmgtech/legato'
import { ICampaign, getCampaignName, getCreator } from 'modules/Campaign'
import { useCategories } from 'modules/Categories'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { UserAva } from 'components/UserAva'
import styles from './row.module.scss'

interface IProps {
  data: ICampaign
}

export const Row: FC<IProps> = ({ data }) => {
  const { getCategoryById } = useCategories()
  const type = data.playlists.length ? 'Playlists' : data.category ? 'Category' : data.type === "Album" ? "UPCs" : "ISRCs"
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Campaign Name</h3>
      <div className={styles.subtitle}>
        <p>{getCampaignName(data)}</p>
        <CopyToClipboard text={getCampaignName(data)} className={styles.copy} />
      </div>
      <div className={styles.content}>
        <table>
          <thead>
            <tr>
              <th>Creator</th>
              <th>Team</th>
              <th>Advertisers</th>
              <th>Label</th>
              <th>{type}<IconButton colorType="info" message="This is a dynamic list and may grow over time." icon="circle-info" /></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <UserAva user={getCreator(data)} className={styles.creator} /><br/>
                {getCreator(data)}<br/>
                {data.user.email}
              </td>
              <td>
                <span className={styles.badge}>{data.team.name}</span>
              </td>
              <td>
                <span className={styles.badge}>{data?.advertisers?.name ?? '—'}</span>
              </td>
              <td>
                <span className={styles.badge}>{data.label.alias}</span>
              </td>
              <td>
                <div className={styles.list}>
                  {
                    data.playlists.length ?
                      data.playlists?.map(e => <span key={e.PLAYLIST_ID} className={styles.badge}>{e.PLAYLIST_NAME}</span>) :
                        data.category ? <span className={styles.badge}>{getCategoryById(data.category)}</span> :
                          data.isrcs?.map(e => <span key={e} className={styles.badge}>{e}</span>)
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
