import { gql } from '@apollo/client'

export const TEAM_FIELDS = gql`
    fragment TeamFields on Team {
        id
        name
        alias
        label_id
        label {
            id
            alias
            name
        }
    }
`

export const LABEL_FIELDS = gql`
    fragment LabelField on Label {
        id
        name
        alias
        advertiser
        advertisers {
            id
            name
        }
        teams {
            id
            name
            alias
        }
    }
`

export const GET_LABELS = gql`
    ${LABEL_FIELDS}
    query {
        getLabels {
            ...LabelField
        }
    }
`

export const GET_LABELS_LIST = gql`
    ${LABEL_FIELDS}
    query ($input: LabelList){
        labelsList(input: $input) {
            data {
                ...LabelField
            }
            total
        }
    }
`

export const TEAMS_LIST = gql`
    ${TEAM_FIELDS}
    query ($input: TeamList){
        getTeams(input: $input) {
            data {
                ...TeamFields
            }
            total
        }
    }
`

export const GET_TEAMS = gql`
    ${TEAM_FIELDS}
    query ($label_id: String!){
        getTeamsList(label_id: $label_id) {
            ...TeamFields
        }
    }
`

export const GET_ROLES = gql`
    query {
        getRoles {
            can_assign_external
            default
            id
            name
        }
    }
`

export const GET_USER_BY_EMAIL = gql`
    query ($email: String!) {
        findUserByEmail(email: $email) {
            id
            email
            first_name
            last_name
            label_id
            role {
                role_id
            }
        }
    }
`
