import { FC, useCallback, useMemo } from 'react'

import { useLabels } from 'modules/Labels'
import { ColumnsType, ILabel, ModalForm, useAdmin } from 'modules/Admin'
import { Table } from "@wmgtech/legato";
import { ActionButton } from "../../../../components/ActionButton";
import { SearchField } from "../../../../components/Fields";
import { Pagination } from "../../../../components/Pagination";
import { ConditionRender, SkeletonTable } from "../../../../components";

const PAGE_SIZE = 10


export const Labels: FC = () => {
  const { labelsList, loading, onSort, sortState, createLabel, updateLabel } = useLabels(PAGE_SIZE)

  const { handleEdit } = useAdmin()

  const onSubmit = useCallback(
    async ({ id, name, advertiser }: ILabel) => {
      if (typeof id === 'undefined') {
        return createLabel(name, advertiser)
      }
      return updateLabel(id, name, advertiser)
    },
    [createLabel, updateLabel],
  )

  const columns = useMemo<ColumnsType<ILabel>[]>(
    () => [
      {
        dataIndex: 'name',
        title: 'Label',
        key: 'label',
        sortable: true,
        width: '300px',
        align: 'left',
      },
      {
        dataIndex: 'teams',
        title: 'Teams',
        key: 'teams',
        sortable: false,
        width: '300px',
        align: 'left',
        render: (list: any[]) => (list || []).map((e, index) => <span
          key={e.name}>{e.name}{list.length !== index + 1 ? ', ' : ''}</span>),
      },
      {
        dataIndex: 'advertisers',
        title: 'Advertiser',
        key: 'advertisers',
        sortable: false,
        width: '800px',
        align: 'left',
        render: (adv: any) => <span>{adv?.name}</span>,
      },
      {
        dataIndex: 'action',
        key: 'action',
        sortable: false,
        width: '63px',
        align: 'center',
        render: (el: any, record: any) => (
          <ActionButton
            handleEdit={() => handleEdit(record as any)}
          />
        ),
      },
    ],
    [handleEdit],
  )

  return (
    <>
      <SearchField/>

      <ConditionRender condition={!loading} component={
        <Table
          data={labelsList.data}
          columns={columns}
          rowKey={({ id }) => id}
          onSort={onSort}
          defaultSort={sortState}
          pagination={false}
        />
      } secondComponent={<SkeletonTable lines={10}/>}
      />

      <Pagination total={labelsList.total} pageSize={PAGE_SIZE}/>

      <ModalForm onSubmit={onSubmit}/>
    </>
  )
}
