import { FC } from 'react'
import { FormSearchArtist } from '../SearchArtist'
import { FormSearchProduct } from '../SearchProduct'
import { FormAdvertiserSelect } from '../SearchAdvertiser'
import { InfoField } from '../InfoField'

export const ReleaseGenerateCampaignForm: FC = () => (
  <>
    <FormAdvertiserSelect />
    <FormSearchArtist isRequired />
    <FormSearchProduct />
    <InfoField />
  </>
)
