import { gql } from '@apollo/client'

export const ADVERTISERS_LIST = gql`
    query {
        getAdvertisers {
            id
            name
            campaigns {
                name
            }
            labels {
                name
            }
        }
    }
`

export const CREATE_ADVERTISER = gql`
    mutation($input: AdvertiserInput) { 
        createAdvertiser(input: $input) {
            id
            name
        }
    }
`

export const UPDATE_ADVERTISER = gql`
    mutation($input: AdvertiserUpdateInput) { 
        updateAdvertiser(input: $input) {
            id
            name
        }
    }
`

export const DELETE_ADVERTISER = gql`
    mutation($id: String) {
        deleteAdvertiser(id: $id) {
            result,
        }
    }
`
