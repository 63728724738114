import { RouteObject } from 'react-router-dom'
import { Labels } from 'modules/Labels'
import { Users } from 'modules/Users'
import { Teams } from 'modules/Teams'
import { Categories } from 'modules/Categories'
import { Advertisers } from 'modules/Advertisers/components'

import { AdminPage } from './Admin'
import { SearchLog } from 'modules/SearchLog'
import { LoginMessage } from 'modules/LoginMessage'

export const adminRoute: RouteObject = {
  path: '',
  element: <AdminPage/>,
  children: [
    {
      path: 'users',
      element: <Users/>,
    },
    {
      path: 'labels',
      element: <Labels/>,
    },
    {
      path: 'teams',
      element: <Teams/>,
    }, 
    {
      path: 'categories',
      element: <Categories/>,
    }, 
    {
      path: 'advertisers',
      element: <Advertisers/>,
    }, 
    {
      path: 'searchlog',
      element: <SearchLog/>,
    }, 
    {
      path: 'loginmessage',
      element: <LoginMessage />,
    },
  ],
};
