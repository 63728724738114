import { FC } from 'react'
import { FormSearchPlaylist } from '../SearchPlaylist'
import { FormSearchArtist } from '../SearchArtist'
import { FormAdvertiserSelect } from '../SearchAdvertiser'
import { InfoField } from '../InfoField'

export const PlaylistGenerateCampaignForm: FC = () => (
  <>
    <FormAdvertiserSelect />
    <FormSearchPlaylist />
    <FormSearchArtist />
    <InfoField />
  </>
)
