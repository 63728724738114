import { IEntityConfig, TEntities } from './types'

export const config: Record<TEntities, IEntityConfig> = {
  users: {
    title: 'User',
    name: 'users',
    fields: [
      {
        element: 'input',
        label: 'First Name',
        name: 'first_name',
        placeholder: 'First Name',
        required: true,
      },
      {
        element: 'input',
        label: 'Last Name',
        name: 'last_name',
        placeholder: 'Last Name',
        required: true,
      },
      {
        element: 'input',
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        required: true,
      },
      {
        element: 'select',
        label: 'Role',
        name: 'role',
        placeholder: 'Select Role',
        datasetName: 'role',
        required: true,
      },
      {
        element: 'select',
        label: 'Label',
        name: 'label',
        placeholder: 'Select Label',
        datasetName: 'labels',
        required: true,
      },
      {
        element: 'select',
        label: 'Team',
        name: 'team',
        placeholder: 'Select Team',
        datasetName: 'teams',
        required: true,
      },
    ],
  },
  teams: {
    title: 'Team',
    name: 'teams',
    fields: [
      {
        element: 'input',
        label: 'Team Name',
        name: 'name',
        placeholder: 'Team',
        required: true,
      },
      {
        element: 'select',
        label: 'Label',
        name: 'label',
        placeholder: 'Select Label',
        datasetName: 'labels',
        // required: true,
        disabled: true,
      },
    ],
  },
  labels: {
    title: 'Label',
    name: 'labels',
    fields: [
      {
        element: 'input',
        label: 'Label Name',
        name: 'name',
        placeholder: 'Label',
        required: true,
      },
      {
        element: 'select',
        label: 'Advertiser',
        name: 'advertiser',
        placeholder: 'Select advertiser',
        datasetName: 'advertisers',
        required: false,
      },
    ],
  },
  searchlog: {
    title: 'Search Log',
    name: 'searchlog',
    ability: (user) => ['1', '48'].includes(user.id),
    fields: [
      //   {
      //     element: 'input',
      //     label: 'Label Name',
      //     name: 'name',
      //     placeholder: 'Label',
      //     required: true,
      //   },
    ],
  },
  categories: {
    title: 'Category',
    name: 'categories',
    fields: [
      {
        element: 'input',
        label: 'Name',
        name: 'name',
        placeholder: 'Name',
        required: true,
      },
    ],
  },
  advertisers: {
    title: 'Advertiser',
    name: 'advertisers',
    fields: [
      {
        element: 'input',
        label: 'Name',
        name: 'name',
        placeholder: 'Advertiser',
        required: true,
      },
    ],
  },
  loginmessage: {
    title: 'Login Message',
    name: 'loginmessage',
    fields: [],
  },
}
