import { FC } from 'react'
import { Modal, Button } from '@wmgtech/legato'
import styles from './confirmation-modal.module.scss'

interface IProps {
  isOpen: boolean
  setClose(): void
  confirmationText: string | JSX.Element
  onAction(): void
  actionText?: string
  additionalConfirmationText?: string
}

export const ConfirmationModal: FC<IProps> = ({
  isOpen,
  setClose,
  confirmationText,
  additionalConfirmationText,
  onAction,
  actionText = 'Delete',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title={actionText}
      onClose={setClose}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.content}>
        {additionalConfirmationText}
        {confirmationText}
      </div>
      <div className={styles.btns}>
        <Button onClick={setClose} containerStyle="outline" colorType="info">Cancel</Button>
        <Button onClick={onAction}>{actionText}</Button>
      </div>
    </Modal>
  )
}
